@import "styles/collections/collections";
@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans&display=swap');
@import "../node_modules/aos/dist/aos.css";

::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

html{
    height: auto;
    width: calc(100vw - (100vw - 100%));
    overflow-x: hidden !important;
    box-sizing: border-box;
}

body {
    margin:0;
    padding:0;
    font-family: 'Noto Sans', 'Source Sans Pro', serif !important;
    min-height: 100%;
    max-width: border-box;
    width:100%;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}

.App{
    overflow-x: hidden !important;
    //max-width: 100vw;
    width:100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.content{
    width:100%;
    height:100%;
    display: grid;
    grid-template-columns: 200px 1fr;
    overflow-x: hidden !important;

}

a{
    text-decoration: none;
}

h1{
    font-size: 30px;
    margin-bottom: 10px;
}

h2{
    font-size: 20px;
}

h3{
    font-size: 18px;
    margin-bottom: 5px;
    font-weight: normal;

}

h4{
    font-size: 16px;
    margin-bottom: 5px;
}

h5{
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: normal;
}

p{
    font-size: 14px;
    margin:0 !important;
}

@media screen and (max-width: 1100px) {

    .content{
        display: block;
        width:100%;
    }

}