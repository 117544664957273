@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans&display=swap);
.dark-mode-button-container {
  display: block;
  width: 100px;
  height: 100%;
  max-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

.switch {
  position: relative;
  display: inline-block;
  width: inherit;
  height: 34px; }

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0; }

/* The slider */
.dark-slider {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  position: absolute;
  width: 100%;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s; }
  .dark-slider p {
    z-index: 1000;
    pointer-events: none;
    font-size: 12px; }

.dark-slider:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 50%;
  left: 0;
  bottom: 0;
  background-color: white;
  transition: .2s linear; }

.dark-slider.light p {
  color: #1a1a1a; }

.dark-slider.light:before {
  -webkit-transform: translateX(0);
  transform: translateX(0%); }

.dark-slider.dark {
  background-color: #333333; }
  .dark-slider.dark p {
    color: white; }
  .dark-slider.dark:before {
    background-color: #1a1a1a;
    -webkit-transform: translateX(100%);
    transform: translateX(100%); }

/* Rounded sliders */
.dark-slider.round {
  border-radius: 34px; }

.dark-slider.round:before {
  border-radius: 34px; }

.dashboard-container {
  width: 100%;
  position: relative;
  height: 100%;
  display: block;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 50px;
  box-sizing: border-box; }

.dashboard-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  width: 100%; }

.dashboard-wrapper-mobile {
  display: none; }

.dashboard-wrapper-pc {
  box-sizing: border-box;
  margin-top: 80px;
  width: 100%;
  max-width: 100%;
  overflow-y: hidden;
  min-height: 100vh;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto auto 1fr auto;
  grid-template-areas: "horizontal horizontal horizontal horizontal" "clock-speed cores uptime uptime" "cpu-load cpu-load uptime uptime" "last-online last-restart os ping";
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  overflow-x: hidden !important; }
  .dashboard-wrapper-pc .information-cards-container {
    -webkit-transform: scale(0);
            transform: scale(0);
    transition: 400ms all ease-in; }
  .dashboard-wrapper-pc .horizontal-1 {
    display: grid;
    width: 100%;
    height: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "server disk ram";
    grid-column-gap: 20px;
    grid-area: horizontal; }
    .dashboard-wrapper-pc .horizontal-1 .server {
      grid-area: server;
      max-height: 200px; }
    .dashboard-wrapper-pc .horizontal-1 .disk {
      grid-area: disk;
      max-height: 200px; }
    .dashboard-wrapper-pc .horizontal-1 .ram {
      grid-area: ram;
      max-height: 200px; }
  .dashboard-wrapper-pc .horizontal-2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
  .dashboard-wrapper-pc .ping {
    grid-area: ping;
    max-height: 200px; }
  .dashboard-wrapper-pc .last-online {
    grid-area: last-online;
    max-height: 200px; }
  .dashboard-wrapper-pc .last-restart {
    grid-area: last-restart;
    max-height: 200px; }
  .dashboard-wrapper-pc .cores {
    grid-area: cores; }
  .dashboard-wrapper-pc .clock-speed {
    grid-area: clock-speed; }
  .dashboard-wrapper-pc .uptime {
    grid-area: uptime; }
  .dashboard-wrapper-pc .cpu-load {
    grid-area: cpu-load; }
  .dashboard-wrapper-pc .os {
    grid-area: os; }

.graph-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%; }

.graph-wrapper {
  width: 100%;
  height: 100%; }

.pop-animation {
  transition: 400ms all ease-in;
  -webkit-transform: scale(1) !important;
          transform: scale(1) !important; }

@media screen and (max-width: 1100px) {
  .dashboard-wrapper-pc {
    display: none; } }

@media screen and (max-width: 600px) {
  .dashboard-container {
    padding: 45px 25px 25px 25px; }
  .dashboard-wrapper-pc {
    display: none; }
  .dashboard-wrapper-mobile {
    box-sizing: border-box;
    margin-top: 50px;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px; } }

.side-nav-container {
  height: 100vh;
  width: 200px;
  border-right: 2px solid;
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  z-index: 100000000;
  grid-template-rows: 80px calc(100vh - 80px - 50px) 50px; }

.side-nav-logo {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 0 0 20px; }
  .side-nav-logo svg {
    width: 50px;
    height: auto; }
  .side-nav-logo path {
    fill: white; }

.side-nav-darkmode {
  width: 100%;
  height: 50px;
  padding-left: 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center; }

.side-nav-menu {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column; }
  .side-nav-menu a {
    width: 100%;
    position: relative; }
  .side-nav-menu li {
    height: auto;
    width: 100%;
    padding: 16px 20px;
    box-sizing: border-box;
    list-style: none; }
    .side-nav-menu li:before {
      content: "";
      left: 0;
      bottom: 0;
      transition: 400ms ease-in all;
      height: 0;
      width: 0;
      background-color: transparent;
      position: absolute; }
    .side-nav-menu li:hover {
      background-color: rgba(0, 0, 0, 0.2); }
      .side-nav-menu li:hover:before {
        content: "";
        left: 0;
        bottom: 0;
        transition: 400ms ease-in all;
        -webkit-transform-origin: bottom;
                transform-origin: bottom;
        height: 100%;
        width: 5px;
        background-color: #e4324c;
        position: absolute; }

.selected-link {
  background-color: rgba(0, 0, 0, 0.2); }
  .selected-link:before {
    content: "";
    left: 0;
    bottom: 0;
    height: 100%;
    width: 5px;
    background-color: #e4324c;
    position: absolute; }

.side-nav-date {
  width: 100%;
  height: auto;
  padding-left: 10px; }

@media screen and (max-width: 1100px) {
  .side-nav-container {
    display: none; }
  .side-nav-outer {
    display: none; } }

.styled-select {
  border-radius: 25px;
  padding-left: 10px;
  padding-bottom: 2px;
  font-family: Poppins;
  font-size: 12px; }
  .styled-select option {
    font-family: Poppins; }

.header-container {
  width: calc(100% - 200px);
  height: 80px;
  z-index: 30000;
  display: block;
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  padding: 5px 50px;
  box-sizing: border-box; }
  .header-container h2, .header-container h3, .header-container h5 {
    margin: 0; }

.header-container-mobile {
  display: none; }

.header-dashboard-information-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row; }

.header-dashboard-title {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column; }

.header-dashboard-information {
  width: 50%;
  min-width: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row; }

.dashboard-information-status {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: auto;
  height: 20px;
  border-radius: 25px;
  position: relative;
  margin-left: 10px; }
  .dashboard-information-status.online p {
    position: relative; }
    .dashboard-information-status.online p:after {
      border-radius: 50%;
      width: 10px;
      height: 10px;
      background-color: green;
      transition: 300ms ease-in all;
      content: "";
      position: absolute;
      top: 0;
      right: -10px;
      -webkit-transform: translate(50%, 25%);
              transform: translate(50%, 25%); }
  .dashboard-information-status.offline p, .dashboard-information-status.offline h2 {
    position: relative; }
    .dashboard-information-status.offline p:after, .dashboard-information-status.offline h2:after {
      border-radius: 50%;
      width: 10px;
      height: 10px;
      background-color: red;
      transition: 300ms ease-in all;
      content: "";
      position: absolute;
      top: 0;
      right: -10px;
      -webkit-transform: translate(50%, 25%);
              transform: translate(50%, 25%); }
  .dashboard-information-status p {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    font-size: 12px;
    margin-left: 10px; }

.header-dashboard-id {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  flex-direction: column; }

@media screen and (max-width: 1100px) {
  .header-container {
    display: none; }
  .header-container-mobile {
    background-color: red;
    width: 100%;
    height: 70px;
    z-index: 5000;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
    box-sizing: border-box; }
    .header-container-mobile.grid {
      display: grid;
      grid-template-columns: 1fr 3fr 1fr; }
    .header-container-mobile .header-logo {
      width: 100%;
      flex: 1 1;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: row;
      z-index: 5000000; }
      .header-container-mobile .header-logo svg {
        width: 50px;
        height: auto; }
    .header-container-mobile .hamburger-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row; }
    .header-container-mobile .header-menu {
      flex: 1 1;
      display: grid;
      grid-template-rows: 1fr 0.50fr;
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      margin-top: 70px;
      transition: 400ms ease-in-out;
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
      width: 70%;
      z-index: 10000; }
      .header-container-mobile .header-menu.open {
        transition: 400ms ease-in-out;
        -webkit-transform: translateX(0%);
                transform: translateX(0%); }
      .header-container-mobile .header-menu .selected-link {
        background-color: rgba(0, 0, 0, 0.2); }
        .header-container-mobile .header-menu .selected-link:before {
          content: "";
          left: 0;
          bottom: 0;
          height: 100%;
          width: 5px;
          background-color: #e4324c;
          position: absolute; }
    .header-container-mobile .header-menu-info {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 20px 50px 20px;
      box-sizing: border-box; }
  .header-dashboard-information {
    width: 100%;
    min-width: auto;
    flex: 1 1;
    flex-direction: column-reverse;
    justify-content: center; }
  .header-overlay {
    position: absolute;
    display: none;
    z-index: 1000;
    top: 0;
    margin-top: 70px;
    left: 0;
    height: 100vh;
    background-color: black;
    opacity: 0;
    width: 100vw;
    transition: 300ms ease-in-out all; }
    .header-overlay.open {
      display: block;
      opacity: 0.4;
      transition: 300ms ease-in-out all; } }

.menu-wrapper {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 25px;
  height: 23px;
  cursor: pointer; }

.hamburger-menu,
.hamburger-menu:after,
.hamburger-menu:before {
  width: 25px;
  height: 3px;
  border-radius: 10px; }

.hamburger-menu {
  position: relative;
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
  background: white;
  transition: all 0ms 300ms; }
  .hamburger-menu.animate {
    background: rgba(255, 255, 255, 0); }

.hamburger-menu:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 10px;
  background: white;
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms cubic-bezier(0.23, 1, 0.32, 1); }

.hamburger-menu:after {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  background: white;
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms cubic-bezier(0.23, 1, 0.32, 1); }

.hamburger-menu.animate:after {
  top: 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1); }

.hamburger-menu.animate:before {
  bottom: 0;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), -webkit-transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1); }

.home-container {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: transparent; }

.home-wrapper {
  display: grid;
  width: 100%;
  justify-items: center;
  height: 100%;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(350px, 1fr)); }

.home-render-dashboards {
  display: flex;
  width: 100%;
  margin-bottom: 100px;
  justify-content: center;
  align-items: center; }

.home-render-dashboards-button {
  display: flex;
  width: auto;
  height: 30px;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  background-color: transparent;
  margin: 0 15px; }
  .home-render-dashboards-button.active {
    background-color: rgba(0, 0, 0, 0.2); }
  .home-render-dashboards-button:hover {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer; }

.general-cards-container {
  padding: 20px 50px;
  box-sizing: border-box;
  width: 80%;
  height: 80%;
  position: relative; }
  .general-cards-container .online {
    width: 100%;
    height: 100%;
    opacity: 0.2;
    position: absolute;
    left: 0;
    top: 0;
    background-color: green; }

.dashboard-list-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 30px;
  box-sizing: border-box; }

.dashboard-inner-wrapper {
  box-sizing: border-box;
  padding: 20px;
  height: 100%;
  width: 100%;
  display: block; }

.dashboard-list-tabs {
  border-top-right-radius: 5px;
  width: 100%;
  height: auto;
  display: block; }
  .dashboard-list-tabs li {
    display: inline-block;
    padding: 16px 20px; }
    .dashboard-list-tabs li.left {
      border-top-left-radius: 5px; }

.dashboard-list-search-container {
  display: block;
  width: 100%;
  height: 50px;
  margin: 10px 0; }

.dashboard-list-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 200px;
  height: 100%;
  border-radius: 5px;
  padding: 0 10px;
  width: 20%;
  min-width: 200px; }
  .dashboard-list-search input {
    height: 90%;
    width: 80%;
    border: none;
    outline-width: 0; }

.dashboard-list-header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: grid;
  width: auto;
  height: auto;
  grid-template-columns: repeat(15, 1fr);
  background-color: #eeeeee; }
  .dashboard-list-header .title {
    grid-template-columns: repeat(5, 1fr); }
  .dashboard-list-header span {
    display: inline-block;
    height: auto;
    width: 100%;
    padding: 20px 10px; }
  .dashboard-list-header .dashboard-list-cell p {
    display: flex;
    border-radius: 3px; }
    .dashboard-list-header .dashboard-list-cell p.link {
      cursor: pointer;
      color: #4460e6; }
    .dashboard-list-header .dashboard-list-cell p.online {
      background-color: green;
      padding: 2px 5px; }
    .dashboard-list-header .dashboard-list-cell p.offline {
      background-color: red;
      padding: 2px 5px; }

.dashboard-list-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-height: 50px;
  max-height: 200px;
  border-bottom: 2px solid; }
  .dashboard-list-wrapper .list-action {
    height: 0;
    position: relative;
    bottom: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    padding: 0 10px;
    transition: 400ms ease-in-out all;
    transition-delay: 300ms; }
    .dashboard-list-wrapper .list-action .action-button {
      opacity: 0;
      border-radius: 3px;
      position: relative;
      display: inline-block;
      height: auto;
      padding: 10px 16px;
      transition: 200ms ease-in-out all; }
      .dashboard-list-wrapper .list-action .action-button.positive {
        background-color: green; }
      .dashboard-list-wrapper .list-action .action-button.caution {
        background-color: orange; }
  .dashboard-list-wrapper.open {
    height: auto; }
    .dashboard-list-wrapper.open .list-action {
      height: 100px;
      min-height: 100px;
      pointer-events: all;
      padding: 20px 10px;
      transition: 400ms ease-in-out all; }
      .dashboard-list-wrapper.open .list-action .action-button {
        opacity: 1;
        position: relative;
        transition: 200ms ease-in-out all;
        transition-delay: 300ms; }
        .dashboard-list-wrapper.open .list-action .action-button:hover {
          cursor: pointer; }

.dashboard-list-inner-wrapper {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(15, 1fr);
  height: auto;
  margin-bottom: 30px; }
  .dashboard-list-inner-wrapper:hover {
    background-color: rgba(0, 0, 0, 0.1); }
  .dashboard-list-inner-wrapper .dashboard-list-cell {
    width: auto;
    height: auto;
    padding: 20px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center; }
    .dashboard-list-inner-wrapper .dashboard-list-cell.center {
      justify-content: center; }
    .dashboard-list-inner-wrapper .dashboard-list-cell p {
      display: flex;
      border-radius: 3px; }
      .dashboard-list-inner-wrapper .dashboard-list-cell p.link {
        cursor: pointer;
        color: #4460e6; }
      .dashboard-list-inner-wrapper .dashboard-list-cell p.online {
        background-color: green;
        padding: 2px 5px; }
      .dashboard-list-inner-wrapper .dashboard-list-cell p.offline {
        background-color: red;
        padding: 2px 5px; }

@media screen and (max-width: 1300px) {
  .home-container {
    margin-top: 80px; }
  .dashboard-list-body {
    overflow-x: scroll;
    overflow-y: hidden;
    min-width: 100%; }
  .dashboard-list-wrapper {
    min-width: calc(10 * 150px); }
  .dashboard-list-inner-wrapper {
    grid-template-columns: repeat(15, minmax(150px, 1fr)); }
  .dashboard-list-header {
    grid-template-columns: repeat(15, minmax(150px, 1fr)); } }

@media screen and (max-width: 450px) {
  .home-container {
    margin-top: 50px; }
  .dashboard-list-wrapper {
    min-width: calc(10 * 45vw); }
  .dashboard-list-inner-wrapper {
    grid-template-columns: repeat(15, minmax(45vw, 1fr)); }
  .dashboard-list-header {
    grid-template-columns: repeat(15, minmax(45vw, 1fr));
    min-width: calc(10 * 45vw); } }

.animated-text.negative-light {
  -webkit-animation: animate-text-light-negative 900ms ease-in;
          animation: animate-text-light-negative 900ms ease-in; }

.animated-text.negative-dark {
  -webkit-animation: animate-text-dark-negative 900ms ease-in;
          animation: animate-text-dark-negative 900ms ease-in; }

.animated-text.positive-light {
  -webkit-animation: animate-text-light-positive 900ms ease-in;
          animation: animate-text-light-positive 900ms ease-in; }

.animated-text.positive-dark {
  -webkit-animation: animate-text-dark-positive 900ms ease-in;
          animation: animate-text-dark-positive 900ms ease-in; }

@-webkit-keyframes animate-text-dark-positive {
  from {
    color: green; }
  to {
    color: white; } }

@keyframes animate-text-dark-positive {
  from {
    color: green; }
  to {
    color: white; } }

@-webkit-keyframes animate-text-dark-negative {
  from {
    color: red; }
  to {
    color: white; } }

@keyframes animate-text-dark-negative {
  from {
    color: red; }
  to {
    color: white; } }

@-webkit-keyframes animate-text-light-negative {
  from {
    color: red; }
  to {
    color: #1a1a1a; } }

@keyframes animate-text-light-negative {
  from {
    color: red; }
  to {
    color: #1a1a1a; } }

@-webkit-keyframes animate-text-light-positive {
  from {
    color: #00a800; }
  to {
    color: #1a1a1a; } }

@keyframes animate-text-light-positive {
  from {
    color: #00a800; }
  to {
    color: #1a1a1a; } }

.fade-in-list-enter {
  opacity: 0; }

.fade-in-list-enter-done {
  opacity: 1;
  transition: 700ms ease-in-out all; }

.fade-in-list-enter-active {
  opacity: 1;
  transition: 700ms ease-in-out all; }

.fade-in-list-exit {
  opacity: 1; }

.fade-in-list-exit-active {
  opacity: 0;
  transition: 700ms ease-in-out all; }

.application-card-container {
  justify-content: flex-start;
  align-content: center;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-color: transparent; }

.application-card {
  float: left;
  width: 25%;
  padding: 0 10px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s; }

.loading-screen-container {
  top: 0;
  left: 0;
  position: absolute;
  z-index: 10000000;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .loading-screen-container svg {
    width: 300px;
    height: auto; }
  .loading-screen-container p {
    margin-top: 30px;
    margin-bottom: 0; }
  .loading-screen-container .asrr-font {
    display: inline-block;
    font-family: Anurati, serif;
    margin-left: 5px; }
  .loading-screen-container .loading-screen-text {
    height: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row; }
    .loading-screen-container .loading-screen-text svg {
      margin-top: 0; }

.graph-loader-container {
  height: 60px;
  width: 50px;
  position: relative;
  display: inline-block; }

.loader-7 .line {
  width: 8px;
  position: absolute;
  border-radius: 5px;
  bottom: 0;
  background: linear-gradient(to bottom, #E32249, #E32249); }

.loader-7 .line1 {
  left: 0;
  -webkit-animation: line-grow 400ms ease alternate infinite;
  animation: line-grow 400ms ease alternate infinite; }

.loader-7 .line2 {
  left: 20px;
  -webkit-animation: line-grow 400ms 0.2s ease alternate infinite;
  animation: line-grow 400ms 0.2s ease alternate infinite; }

.loader-7 .line3 {
  left: 40px;
  -webkit-animation: line-grow 400ms 0.4s ease alternate infinite;
  animation: line-grow 400ms 0.4s ease alternate infinite; }

@-webkit-keyframes line-grow {
  0% {
    height: 0; }
  100% {
    height: 75%; } }

@keyframes line-grow {
  0% {
    height: 0; }
  100% {
    height: 75%; } }

.loading-screen-appear {
  opacity: 0; }

.loading-screen-appear-active {
  opacity: 1;
  transition: opacity 700ms; }

.loading-screen-enter {
  opacity: 1; }

.loading-screen-enter-active {
  opacity: 1;
  transition: opacity 700ms; }

.loading-screen-exit {
  opacity: 1; }

.loading-screen-exit-active {
  opacity: 0;
  transition: opacity 700ms; }

.fade-in-enter {
  opacity: 0; }

.fade-in-enter-active {
  opacity: 1;
  transition: 2300ms; }

.test {
  width: 100px;
  height: 100px;
  background-color: red; }

.information-cards-container {
  padding: 20px 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative; }
  .information-cards-container .information-card-percentage {
    position: absolute;
    bottom: 0;
    height: 2px;
    left: 0;
    width: 0;
    background-color: #e4324c;
    transition: 200ms ease-in all;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    z-index: 100; }
  .information-cards-container:hover .information-card-percentage {
    transition: 200ms ease-in all;
    -webkit-transform: scaleY(2);
            transform: scaleY(2);
    -webkit-transform-origin: bottom;
            transform-origin: bottom; }
  .information-cards-container h2, .information-cards-container h3, .information-cards-container h4, .information-cards-container h5 {
    width: auto;
    display: block;
    z-index: 100;
    margin: 0 0 5px 0; }

._1-grid {
  height: 100%;
  width: 100%;
  display: grid;
  grid-row-gap: 20px;
  align-items: start;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "main"; }

._3-grid {
  height: 100%;
  width: 100%;
  display: grid;
  grid-row-gap: 20px;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "main main" "sub-one sub-two"; }
  ._3-grid .sub-stat_2 {
    text-align: right; }

._4-grid {
  height: 100%;
  width: 100%;
  display: grid;
  grid-row-gap: 20px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "main sub-one" "sub-two sub-three"; }
  ._4-grid .sub-stat_1 {
    display: flex;
    flex-direction: column;
    text-align: right;
    justify-content: center; }
  ._4-grid .sub-stat_2 {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  ._4-grid .sub-stat_3 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: right; }

.main-stat {
  grid-area: main; }

.sub-stat_1 {
  grid-area: sub-one; }

.sub-stat_2 {
  grid-area: sub-two; }

.sub-stat_3 {
  grid-area: sub-three; }

.main-stat {
  display: flex;
  justify-content: center;
  flex-direction: column; }

.server {
  grid-area: Server; }

.disk {
  grid-area: Disk; }

.ram {
  grid-area: Ram; }

.cores {
  grid-area: Cores; }

.clock-speed {
  grid-area: Clock-Speed; }

.uptime {
  grid-area: Uptime; }

.cpu-load {
  grid-area: CPU-Load; }

.os {
  grid-area: OS; }

.ping {
  grid-area: Ping; }

@media screen and (max-width: 1400px) {
  .information-cards-container {
    padding: 20px 20px; } }

@media screen and (max-width: 600px) {
  .information-cards-container {
    padding: 20px 25px;
    height: auto; }
  ._1-grid {
    height: 100%;
    width: 100%;
    display: grid;
    grid-row-gap: 20px;
    align-items: start;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "main"; }
  ._3-grid {
    height: 100%;
    width: 100%;
    display: grid;
    grid-row-gap: 20px;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "main main" "sub-one sub-two"; }
    ._3-grid .sub-stat_2 {
      text-align: right; }
  ._4-grid {
    height: 100%;
    width: 100%;
    display: grid;
    grid-row-gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "main sub-one" "sub-two sub-three"; }
    ._4-grid .sub-stat_1 {
      display: flex;
      flex-direction: column;
      text-align: right;
      justify-content: center; }
    ._4-grid .sub-stat_2 {
      display: flex;
      flex-direction: column;
      justify-content: center; }
    ._4-grid .sub-stat_3 {
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: right; }
  .main-stat {
    grid-area: main; }
  .sub-stat_1 {
    grid-area: sub-one; }
  .sub-stat_2 {
    grid-area: sub-two; }
  .sub-stat_3 {
    grid-area: sub-three; }
  .main-stat {
    display: flex;
    justify-content: center;
    flex-direction: column; } }

::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05); }

html {
  height: auto;
  width: calc(100vw - (100vw - 100%));
  overflow-x: hidden !important;
  box-sizing: border-box; }

body {
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans', 'Source Sans Pro', serif !important;
  min-height: 100%;
  max-width: border-box;
  width: 100%;
  overflow-x: hidden !important;
  overflow-y: hidden !important; }

.App {
  overflow-x: hidden !important;
  width: 100%;
  box-sizing: border-box; }

.content {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 200px 1fr;
  overflow-x: hidden !important; }

a {
  text-decoration: none; }

h1 {
  font-size: 30px;
  margin-bottom: 10px; }

h2 {
  font-size: 20px; }

h3 {
  font-size: 18px;
  margin-bottom: 5px;
  font-weight: normal; }

h4 {
  font-size: 16px;
  margin-bottom: 5px; }

h5 {
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: normal; }

p {
  font-size: 14px;
  margin: 0 !important; }

@media screen and (max-width: 1100px) {
  .content {
    display: block;
    width: 100%; } }

