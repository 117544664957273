.styled-select{
  border-radius: 25px;
  padding-left: 10px;
  padding-bottom: 2px;
  option{
    font-family: Poppins;

  }
  font-family: Poppins;
  font-size: 12px;

}
