.application-card-container {
  justify-content: flex-start;
  align-content: center;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-color: transparent;
}

.application-card {
  float: left;
  width: 25%;
  padding: 0 10px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

