$delay: 100ms;
$function: 200ms;
$duration: 400ms;

.loading-screen-container{
  top:0;
  left:0;
  position: absolute;
  z-index: 10000000;
  width:100vw;
  height:100vh;
  @include flex(center, center, column);
  svg{
    width: 300px;
    height:auto;
  }

  p{
    margin-top: 30px;
    margin-bottom:0;
  }

  .asrr-font{
    display: inline-block;
    font-family: Anurati, serif;
    margin-left: 5px;
  }

  .loading-screen-text{
    height:auto;
    margin-top: 20px;
    @include flex(center, center, row);
    svg{
      margin-top: 0;
    }
  }

}
  //Graph loader
  .graph-loader-container{
    height:60px;
    width:50px;
    position: relative;
    display: inline-block;
  }

  .loader-7 .line {
    width: 8px;
    position: absolute;
    border-radius: 5px;
    bottom: 0;
    background: -webkit-gradient(linear, left top, left bottom, from(#E32249), to(#E32249));
    background: -webkit-linear-gradient(top, #E32249, #E32249);
    background: -o-linear-gradient(top, #E32249, #E32249);
    background: linear-gradient(to bottom, #E32249, #E32249);
  }

  .loader-7 .line1 {
    left: 0;
    -webkit-animation: line-grow $duration ease alternate infinite;
    animation: line-grow $duration ease alternate infinite;
  }

  .loader-7 .line2 {
    left: 20px;
    -webkit-animation: line-grow $duration 0.2s ease alternate infinite;
    animation: line-grow $duration 0.2s ease alternate infinite;
  }

  .loader-7 .line3 {
    left: 40px;
    -webkit-animation: line-grow $duration 0.4s ease alternate infinite;
    animation: line-grow $duration 0.4s ease alternate infinite;
  }


@keyframes line-grow {
  0% {
    height: 0;
  }
  100% {
    height: 75%;
  }
}


 //Loading screen animation

.loading-screen-appear{
  opacity:0;
}

.loading-screen-appear-active{
  opacity:1;
  transition: opacity 700ms;
}

.loading-screen-enter{
  opacity:1;
}

.loading-screen-enter-active{
  opacity:1;
  transition: opacity 700ms;
}

.loading-screen-exit{
  opacity:1;
}

.loading-screen-exit-active{
  opacity:0;
  transition: opacity 700ms;
}

.fade-in-enter{
  opacity: 0;
}

.fade-in-enter-active{
  opacity: 1;
  transition: 2300ms;
}

//.loading-screen-exit-done{
//  opacity:0;
//}

.test{
  width:100px;
  height:100px;
  background-color: red;
}
