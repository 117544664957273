.dashboard-container {
  width: 100%;
  position: relative;
  height: 100%;
  //max-height: 130vh;
  display: block;
  @include flex(flex-start, center, column);
  padding: 50px;
  box-sizing: border-box;
}

.dashboard-wrapper{
  @include flex(flex-start, center, column);
  height:100%;
  min-height: 100vh;
  width:100%;

}

.dashboard-wrapper-mobile{
  display: none;
}

.dashboard-wrapper-pc {
  box-sizing: border-box;
  margin-top: 80px;
  width: 100%;
  max-width: 100%;
  overflow-y: hidden;
  min-height: 100vh;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto auto 1fr auto;
  grid-template-areas: "horizontal horizontal horizontal horizontal" "clock-speed cores uptime uptime" "cpu-load cpu-load uptime uptime" "last-online last-restart os ping";
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  overflow-x: hidden !important;


  .information-cards-container {
    transform: scale(0);
    transition: 400ms all ease-in;
  }

  .horizontal-1 {
    display: grid;
    width: 100%;
    height: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "server disk ram";
    grid-column-gap: 20px;
    grid-area: horizontal;

    .server {
      grid-area: server;
      max-height: 200px;
    }

    .disk {
      grid-area: disk;
      max-height: 200px;
    }

    .ram {
      grid-area: ram;
      max-height: 200px;
    }

  }

  .horizontal-2 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .ping {
    grid-area: ping;
    max-height: 200px;
  }

  .last-online {
    grid-area: last-online;
    max-height: 200px;
  }

  .last-restart {
    grid-area: last-restart;
    max-height: 200px;
  }

  .cores {
    grid-area: cores;
  }

  .clock-speed {
    grid-area: clock-speed;
  }

  .uptime {
    grid-area: uptime;
    //max-height: 700px;
  }

  .cpu-load {
    grid-area: cpu-load;
    //max-height: 500px;
  }

  .os {
    grid-area: os;
  }
}

.graph-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.graph-wrapper {
  width: 100%;
  height: 100%;
}


.pop-animation {
  transition: 400ms all ease-in;
  transform: scale(1) !important;
}

@media screen and (max-width: 1100px) {

  .dashboard-wrapper-pc {
    display: none;
  }

  //.dashboard-wrapper {
  //  grid-template-columns: 1fr 1fr;
  //  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  //  grid-template-areas: "server disk" "ram ipad" "cpu-load uptime" "cpu-load uptime" "os ping";
  //  grid-column-gap: 20px;
  //  grid-row-gap: 20px;
  //
  //  .ipad {
  //    display: grid;
  //    grid-template-columns: 1.5fr 0.5fr 1fr;
  //    grid-template-rows: 1fr 1fr;
  //    grid-template-areas: "clock-speed cores cores" ". . .";
  //    grid-area: horizontal;
  //  }
  //
  //
  //  .server {
  //    grid-area: server;
  //    max-height: 200px;
  //  }
  //
  //  .disk {
  //    grid-area: disk;
  //    max-height: 200px;
  //  }
  //
  //  .ram {
  //    grid-area: ram;
  //    max-height: 200px;
  //  }
  //}


}

@media screen and (max-width: 600px) {

  .dashboard-container{
    padding: 45px 25px 25px 25px;

  }

  .dashboard-wrapper-pc{
    display: none;
  }

  .dashboard-wrapper-mobile {
    box-sizing: border-box;
    margin-top: 50px;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

}