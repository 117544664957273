.dark-mode-button-container{
  display: block;
  width:100px;
  height:100%;
  max-height: 40px;
  @include flex(center, center, column);

}

.switch {
  position: relative;
  display: inline-block;
  width: inherit;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.dark-slider {
  @include flex(space-around, center, row);
  position: absolute;
  width:100%;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;

  p{
    z-index: 1000;
    pointer-events: none;
    font-size: 12px;
  }
}

.dark-slider:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 50%;
  left: 0;
  bottom: 0;
  background-color: white;
  -webkit-transition: .4s;
  transition: .2s linear ;
}

.dark-slider {
  &.light{
    p{
      color:#1a1a1a;
    }

    &:before{
      -webkit-transform: translateX(-0);
      -ms-transform: translateX(-0);
      transform: translateX(-0%);
    }
  }

  &.dark{
    background-color: #333333;
    p{
      color:white;
    }
    &:before{
      background-color: #1a1a1a;
      -webkit-transform: translateX(100%);
      -ms-transform: translateX(100%);
      transform: translateX(100%);
    }
  }

}

/* Rounded sliders */
.dark-slider.round {
  border-radius: 34px;
}

.dark-slider.round:before {
  border-radius: 34px;
}
