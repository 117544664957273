@mixin size ($width, $height){
  width:$width;
  height:$height;
}

@mixin flex ($x, $y, $direction){
  display: flex;
  justify-content: $x;
  align-items: $y;
  flex-direction: $direction;
}

@mixin size ($width, $height){
  width:$width;
  height:$height;
}

@mixin pcPadding ($top, $side) {
  padding: $top $side;
}

@mixin mobilePadding ($top, $side) {
  padding: $top $side;
}
