.side-nav-container{
    height:$sideBarHeight;
    width:200px;
    border-right: 2px solid;
    position: fixed;
    top:0;
    left:0;
    display: grid;
    //grid-template-rows: 0.25fr 1fr 0.5fr;
    z-index: 100000000;
    grid-template-rows: $headerHeight $sideBarMenu $sideBarDate;
}

.side-nav-logo{
    width:100%;
    height: $headerHeight;
    @include flex(center, flex-start, column);
    box-sizing: border-box;
    padding: 0 0 0 20px;

    svg{
        width:50px;
        height:auto;
    }
    path{
        fill:white;
    }
}

.side-nav-darkmode{
    width:100%;
    height:50px;
    padding-left: 15px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

}

.side-nav-menu{
    @include flex(flex-start, flex-start, column);
    //padding-top:50px;

    a{
        width:100%;
        position: relative;
    }


    li{
        height:auto;
        width:100%;
        padding: 16px 20px;
        box-sizing: border-box;
        list-style: none;
        &:before {
            content:"";
            left:0;
            bottom:0;
            transition: 400ms ease-in all;
            height:0;
            width:0;
            background-color:transparent;
            position: absolute;
        }

        &:hover{
            background-color:rgba(0, 0, 0, 0.2);
            &:before {
                content:"";
                left:0;
                bottom:0;
                transition: 400ms ease-in all;
                transform-origin: bottom;
                height:100%;
                width:5px;
                background-color:$primary-tint;
                position: absolute;
            }
        }
    }
}

.selected-link{
    background-color:rgba(0, 0, 0, 0.2);
    &:before {
        content:"";
        left:0;
        bottom:0;
        height:100%;
        width:5px;
        background-color:$primary-tint;
        position: absolute;
    }
}

.side-nav-date{
    width:100%;
    height:auto;
    padding-left: 10px;
}


@media screen and (max-width: 1100px) {

    .side-nav-container{
        display: none;
    }

    .side-nav-outer{
        display: none;
    }

}

@media screen and (max-width: $mobile) {


}