.information-cards-container {
  padding: 20px 50px;
  box-sizing: border-box;
  @include flex(space-between, flex-start, column);
  width: 100%;
  height: 100%;
  position: relative;

  .information-card-percentage {
    position: absolute;
    bottom: 0;
    height: 2px;
    left: 0;
    width: 0;
    background-color: $primary-tint;
    transition: 200ms ease-in all;
    transform: scaleY(1);
    transform-origin: bottom;
    z-index: 100;
  }

  &:hover {
    .information-card-percentage {
      transition: 200ms ease-in all;
      transform: scaleY(2);
      transform-origin: bottom;
    }
  }

  h2, h3, h4, h5 {
    width: auto;
    display: block;
    z-index: 100;
    margin: 0 0 5px 0;
  }


}

._1-grid {
  height: 100%;
  width: 100%;
  display: grid;
  grid-row-gap: 20px;
  align-items: start;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "main";
}

._3-grid {
  height: 100%;
  width: 100%;
  display: grid;
  grid-row-gap: 20px;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "main main" "sub-one sub-two";

  .sub-stat_2 {
    text-align: right;
  }
}

._4-grid {
  height: 100%;
  width: 100%;
  display: grid;
  grid-row-gap: 20px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "main sub-one" "sub-two sub-three";

  .sub-stat_1 {
    display: flex;
    flex-direction: column;
    text-align: right;
    justify-content: center;

  }

  .sub-stat_2 {
    display: flex;
    flex-direction: column;
    justify-content: center;

  }

  .sub-stat_3 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: right;
  }


}

.main-stat {
  grid-area: main;
}

.sub-stat_1 {
  grid-area: sub-one;
}

.sub-stat_2 {
  grid-area: sub-two;
}

.sub-stat_3 {
  grid-area: sub-three;
}

.main-stat {
  display: flex;
  justify-content: center;
  flex-direction: column;

}

.server {
  grid-area: Server;
}

.disk {
  grid-area: Disk;
}

.ram {
  grid-area: Ram;
}

.cores {
  grid-area: Cores;
}

.clock-speed {
  grid-area: Clock-Speed;
}

.uptime {
  grid-area: Uptime;
}

.cpu-load {
  grid-area: CPU-Load;
}

.os {
  grid-area: OS;
}

.ping {
  grid-area: Ping;
}

@media screen and (max-width: 1400px) {
  .information-cards-container {
    padding: 20px 20px;
    }
}

@media screen and (max-width: 600px) {

  .information-cards-container {
    padding: 20px 25px;
    height: auto;

  }


  ._1-grid {
    height: 100%;
    width: 100%;
    display: grid;
    grid-row-gap: 20px;
    align-items: start;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "main";
  }

  ._3-grid {
    height: 100%;
    width: 100%;
    display: grid;
    grid-row-gap: 20px;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "main main" "sub-one sub-two";

    .sub-stat_2 {
      text-align: right;
    }
  }

  ._4-grid {
    height: 100%;
    width: 100%;
    display: grid;
    grid-row-gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "main sub-one" "sub-two sub-three";

    .sub-stat_1 {
      display: flex;
      flex-direction: column;
      text-align: right;
      justify-content: center;

    }

    .sub-stat_2 {
      display: flex;
      flex-direction: column;
      justify-content: center;

    }

    .sub-stat_3 {
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: right;
    }


  }

  .main-stat {
    grid-area: main;
  }

  .sub-stat_1 {
    grid-area: sub-one;
  }

  .sub-stat_2 {
    grid-area: sub-two;
  }

  .sub-stat_3 {
    grid-area: sub-three;
  }

  .main-stat {
    display: flex;
    justify-content: center;
    flex-direction: column;

  }
}

