.header-container{
  width:$headerWidth;
  height:$headerHeight;
  z-index: 30000;
  display: block;
  position: fixed;
  right:0;
  top:0;
  @include flex(flex-start, center, row);
  padding: 5px 50px;
  box-sizing: border-box;

  h2, h3, h5{
    margin:0;
  }
}

.header-container-mobile{
  display: none;
}

.header-dashboard-information-wrapper{
  width:100%;
  height: 100%;
  @include flex(space-between, center, row);
}

.header-dashboard-title{
  width:100%;
  height: 100%;
  @include flex(space-evenly, flex-start, column);
}

.header-dashboard-information{
  width:50%;
  min-width: 400px;
  @include flex(flex-start, center, row);
}

.dashboard-information-status{
  @include flex(flex-start, center, row);
  width:auto;
  height:20px;
  border-radius: 25px;
  position: relative;
  margin-left: 10px;

  &.online{
    p {
      position: relative;
      &:after {
        border-radius: 50%;
        width: 10px;
        height: 10px;
        background-color: green;
        transition: 300ms ease-in all;
        content: "";
        position: absolute;
        top: 0;
        right: -10px;
        transform: translate(50%, 25%);
      }
    }
  }

  &.offline{
    p, h2 {
      position: relative;
      &:after {
        border-radius: 50%;
        width: 10px;
        height: 10px;
        background-color: red;
        transition: 300ms ease-in all;
        content: "";
        position: absolute;
        top: 0;
        right: -10px;
        transform: translate(50%, 25%);
      }
    }
  }


  p{
    user-select: none;
    font-size: 12px;
    margin-left:10px;
  }
}

.header-dashboard-id{
  width:100%;
  height:100%;
  @include flex(space-evenly, flex-end, column);

}


@media screen and (max-width: 1100px) {

  .header-container{
    display: none;
  }

  .header-container-mobile{
    background-color: red;
    width:100%;
    height:70px;
    z-index: 5000;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
    box-sizing: border-box;

    &.grid{
      display: grid;
      grid-template-columns: 1fr 3fr 1fr;
    }

    .header-logo{
      width:100%;
      flex:1;
      height:100%;
      @include flex(flex-end, center, row);
      z-index: 5000000;
      svg{
        width:50px;
        height:auto;
      }
    }

    .hamburger-container{
      width:100%;
      height:100%;
      @include flex(flex-start, center, row);
    }

    .header-menu{
      flex:1;
      display: grid;
      grid-template-rows: 1fr 0.50fr;
      position: fixed;
      top:0;
      left:0;
      height:100vh;
      margin-top:70px;
      transition: 400ms ease-in-out;
      transform:translateX(-100%);
      width: 70%;
      z-index: 10000;


      &.open{
        transition: 400ms ease-in-out;
        transform:translateX(0%);
      }

      .selected-link{
        background-color:rgba(0, 0, 0, 0.2);
        &:before {
          content:"";
          left:0;
          bottom:0;
          height:100%;
          width:5px;
          background-color:$primary-tint;
          position: absolute;
        }
      }
    }
    .header-menu-info{
      height:100%;
      width:100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 20px 50px 20px;
      box-sizing: border-box;
    }
  }

  .header-dashboard-information{
    width:100%;
    min-width: auto;
    flex:1;
    flex-direction: column-reverse;
    justify-content: center;
  }

  .header-overlay{
    position: absolute;
    display: none;
    z-index: 1000;
    top:0;
    margin-top: 70px;
    left:0;
    height:100vh;
    background-color: black;
    opacity: 0;
    width:100vw;
    transition: 300ms ease-in-out all;

    &.open{
      display: block;
      opacity: 0.4;
      transition: 300ms ease-in-out all;
    }

  }
}